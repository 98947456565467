<template>
  <div>
    <div v-if="label" class="input-heading" :class="{ '-error': !!error }">
      <label>{{ label }}</label>
    </div>
    <div class="text-field-container">
      <VTextField
        v-model="text"
        :disabled="disabled"
        :placeholder="placeholder"
        :hide-details="true"
        :suffix="suffix"
        :type="type"
        :height="height"
        :autocomplete="autocomplete"
        :readonly="readonly"
        outlined
        dense
        :error="!!error"
        class="text-field-container-input"
        @keydown.enter="enterAction"
        @blur="onBlur"
        @compositionstart="onCompositionStart"
        @compositionend="onCompositionEnd"
      />
      <div
        v-show="limit"
        :style="limitStyle"
        class="text-field-container-limit"
      >
        {{ String(value).length }} / {{ limit }}
      </div>
    </div>
    <div v-if="error" class="errorMessage">
      <slot v-if="hasSlotError" name="error" />
      <div v-else>{{ error }}</div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: [String, Number],
      default: ''
    },
    placeholder: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: 'text'
    },
    limit: {
      type: Number,
      default: undefined
    },
    suffix: {
      type: String,
      default: undefined
    },
    disabled: {
      type: Boolean,
      default: false
    },
    height: {
      type: String,
      default: '49px'
    },
    autocomplete: {
      type: String,
      default: undefined
    },
    limitMarginTop: {
      type: String,
      default: '4px'
    },
    readonly: {
      type: Boolean,
      default: false
    },
    isComposing: {
      type: Boolean,
      default: false
    },
    error: {
      type: String,
      default: ''
    },
    label: {
      type: String,
      default: ''
    }
  },
  computed: {
    text: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    },
    limitStyle() {
      const colorStyle =
        String(this.value).length > this.limit ? { color: '#D0021B' } : {}

      const margintTopStyle = this.limitMarginTop
        ? { marginTop: this.limitMarginTop }
        : {}

      return {
        ...colorStyle,
        ...margintTopStyle
      }
    },
    hasSlotError() {
      return !!this.$scopedSlots.error
    }
  },
  methods: {
    onCompositionStart() {
      this.$emit('update:isComposing', true)
    },
    onCompositionEnd() {
      this.$emit('update:isComposing', false)
    },
    enterAction(e) {
      // キーボードを閉じる処理はmobile tabletに限定
      if (!e.isComposing && this.$device.isMobileOrTablet) {
        e.target.blur()
      }
    },
    onBlur(e) {
      this.$emit('blur', e.target.value)
    }
  }
}
</script>

<style lang="scss" scoped>
.text-field-container {
  position: relative;

  &-input::v-deep {
    font-size: 14px;
    padding-top: 0;
    border-radius: 8px;

    input:-webkit-autofill {
      box-shadow: 0 0 0 1000px white inset;
    }

    .v-text-field__slot input::placeholder {
      color: $gray40;
      font-size: 14px;
      font-weight: 300;
    }

    .v-input__slot {
      padding: 12px 16px !important;
      &::before {
        border-color: #cccccc !important;
      }
    }

    .v-text-field__slot input {
      padding: 0 !important;
    }
  }

  &-limit {
    text-align: right;
    font-size: 10px;
    line-height: 1;
    color: #c4c4c4;
  }
}

.input-heading {
  display: flex;
  font-size: 13px;
  font-style: normal;
  font-weight: 300;
  line-height: 150%;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 4px;

  &.-error {
    color: $red100;
  }
}

.errorMessage {
  color: $red100;
  font-size: 12px;
  margin-top: 8px;
  line-height: 1.5;
  font-weight: 300;
}
</style>
