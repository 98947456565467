<template>
  <div class="wrap">
    <VApp>
      <div class="page-container">
        <div class="pagewrap">
          <div class="logoArea">
            <div>
              <img
                class="logoImg"
                src="~/assets/images/docsiru_logo.png"
                alt="logo"
              />
            </div>
          </div>
          <div class="loginForm">
            <form v-if="!currentUser" @submit.prevent="loginFunc">
              <div class="formInput">
                <OutlinedTextFieldComposition
                  v-model="formUsername"
                  name="formUsername"
                  type="text"
                  label="メールアドレス"
                  placeholder="mail@example.com"
                />
              </div>
              <div class="formInput">
                <OutlinedTextFieldComposition
                  v-model="formPassword"
                  autocomplete="current-password"
                  name="formPassword"
                  type="password"
                  label="パスワード"
                  placeholder="パスワードをご入力ください"
                >
                </OutlinedTextFieldComposition>
              </div>
              <p v-if="errorMsg" class="errorMessage">
                {{ errorMsg }}
              </p>
              <div class="loginButton">
                <Button
                  rounded
                  font-size="16px"
                  color="primary"
                  height="48px"
                  type="submit"
                >
                  ログイン
                </Button>
              </div>
            </form>
            <div class="passwordForgot">
              <a :href="resetPasswordUrl">パスワードをお忘れの方はこちら</a>
            </div>
          </div>

          <div class="migrationArea">
            <div class="migrationItem">
              <Button
                rounded
                font-size="16px"
                color="primary"
                :outlined="true"
                height="48px"
                to="/agree-terms"
              >
                新規会員登録
              </Button>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </VApp>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'

import OutlinedTextFieldComposition from '~/components/commons/atoms/OutlinedTextFieldComposition.vue'
import Button from '~/components/commons/atoms/Button'
import Footer from '~/components/commons/organisms/Footer'
import config from '~/helper/constant'

export default {
  components: { OutlinedTextFieldComposition, Button, Footer },
  layout: 'no-auth',
  middleware: ['redirectToTopIfLogined'],
  data() {
    return {
      formUsername: '',
      formPassword: '',
      errorMsg: ''
    }
  },
  head() {
    return {
      title: 'ログイン'
    }
  },
  computed: {
    ...mapState(['currentUser']),
    resetPasswordUrl() {
      switch (process.env.STAGE) {
        case 'prod':
          return 'https://corep.docshiru.com/regist/resetPassword'
        case 'stg':
          return 'https://stg-corep.docshiru.com/regist/resetPassword'
        case 'qa':
          return 'https://qa-corep.docshiru.com/regist/resetPassword'
        default:
          return 'https://dev-corep.docshiru.com/regist/resetPassword'
      }
    }
  },

  methods: {
    ...mapActions(['logout']),
    async loginFunc() {
      try {
        await this.$authRepository.login({
          username: this.formUsername,
          password: this.formPassword
        })

        const redirectPath = this.$cookies.get(config.redirectKey)
        if (redirectPath) {
          this.$cookies.remove(config.redirectKey)
          location.href = redirectPath
        } else {
          // TODO: nuxtServerInit でログイン状態を取得しているため、route.push での遷移ができてないのでいずれ修正する
          location.href = '/'
        }
      } catch (error) {
        this.errorMsg = 'メールアドレスもしくはパスワードが違います'
      }
    },
    async logoutFunc() {
      await this.logout()
    }
  }
}
</script>

<style scoped lang="scss">
.wrap {
  display: flex;
  align-items: center;
  min-height: 100vh;
  flex-direction: column;
}
.page-container {
  margin: 0 auto;
  width: 100%;
  flex-grow: 1;
}
.pagewrap {
  padding: 171px 24px 34px 24px;
  max-width: 800px;
  margin: 0 auto;
  @include desktop() {
    padding: 144px 63px 80px 63px;
    margin-bottom: 40px;
    text-align: center;
  }
  @include tablet() {
    padding: 172px 40px 0px 40px;
  }
}
.logoArea {
  display: flex;
  justify-content: center;
  margin-bottom: 24px;
  @include tablet() {
    padding: 0 16px;
  }
}
.logoImg {
  width: 180px;
  max-width: 100%;
}

.loginForm {
  margin-bottom: 32px;
  @include tablet() {
    padding: 0 16px;
  }
}
.formInput {
  width: 100%;
  margin-bottom: 24px;
  text-align: left;
}

.loginButton {
  margin: auto;
  max-width: 360px;
}

.passwordForgot {
  text-align: center;
  margin-top: 16px;
  font-size: 14px;
  font-weight: 600;
  a {
    color: #4b72d5;
    text-decoration: none;
  }
  @include desktop() {
    text-align: left;
  }
  @include tablet() {
    text-align: left;
  }
}

.migrationArea {
  @include tablet() {
    padding: 0 16px;
  }
}
.migrationItem {
  margin: auto;
  max-width: 360px;
}

.errorMessage {
  color: $red100;
  font-size: 12px;
  margin-top: 8px;
  line-height: 1.5;
  font-weight: 300;
}

::v-deep .migrationItem .button-container-button {
  border: 2px solid;
}
</style>
